.base-text .body {
  margin: auto;
  width: 100%;
  padding: 4px;
  text-align: justify;
  max-width: 900px;
}

.base-text .header {
  margin: auto;
  width: 100%;
  text-align: center;
  max-width: 700px;
}

.base-text h1 {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
  font-family: 'All Round Gothic Book', sans-serif;
}

.base-text h2 {
  font-size: 1.8em;
  margin-top: 1.5em;
  margin-bottom: 1em;
  font-family: 'All Round Gothic Book', sans-serif;
}

  .base-text h3 {
  font-size: 1.75em;
  margin-bottom: 0.5em;
  font-family: 'All Round Gothic Book', sans-serif;
}

.base-text h4 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  font-family: 'All Round Gothic Book', sans-serif;
}

.base-text p {
  line-height: 1.5;
  margin-bottom: 1em;
  font-family: 'Montserrat', sans-serif;
}

.base-text ul {
  list-style-type: disc;
  padding-left: 2em;
  font-family: 'Montserrat', sans-serif;
}

.base-text li {
  margin-bottom: 0.5em;
  font-family: 'Montserrat', sans-serif;
}

.base-text a {
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.base-text p, .base-text ul, .base-text li, .base-text a {
  font-size: 14px;
}

/* Small screens (sm: ≥640px) */
@media screen and (min-width: 640px) {
  .base-text p, .base-text ul, .base-text li, .base-text a {
    font-size: 16px;
  }
}

/* Medium screens (md: ≥768px) */
@media screen and (min-width: 768px) {
  .base-text p, .base-text ul, .base-text li, .base-text a {
    font-size: 18px;
  }
}


