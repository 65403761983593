$PRIMARY_COLOR: var(--primary-color-lb);
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './spacings.scss';
@import './text.scss';
@import './base.scss';

@import '~react-toastify/dist/ReactToastify.min.css';


@layer base {
  :root {
    --whiteValue: 0, 0%, 100%;
    --white: hsl(var(--whiteValue));
    --whiteInter: rgba(255, 255, 255, 0.8);
    --white2: hsl(0, 0%, 95%);
    --dark: #27272a;
    --darkInter: rgba(39, 39, 42, 0.8);
    --darkValue: 236, 7%, 10%;
    --darkValue2: 236, 7%, 12%;
    --dark2: #18181B;


    --background: var(--white);
    --backgroundValue: var(--whiteValue);
    --backgroundInter: var(--whiteInter);
    --background2: var(--white2);
    --foreground: var(--dark);

    --primary-color-lb: #009bdf;
    --primary-color-jbda: #005baa;

    --primary: #{$PRIMARY_COLOR};
    --primary-bright: hsl(from var(--primary) h s calc(l*1.5));
    --primary-foreground: var(--white);

    --secondary: var(--primary-foreground);
    --secondary-foreground: var(--primary);

    --accent: hsl(198 49% 90%);
    --accent-foreground: var(--primary);

    --muted: hsl(236, 7%, 92%);
    --muted-foreground: hsl(236, 7%, 44%);

    --destructive: hsl(5, 75%, 55%);
    --destructive-foreground: hsl(210 40% 98%);

    --popover: hsl(0 0% 95%);
    --popover-foreground: hsl(222.2 47.4% 11.2%);

    --card: hsl(236, 7%, 98%);
    --card-foreground: hsl(236, 7%, 10%);

    --border: hsl(236 7% 85%);
    --border-shadow: hsl(240 6% 90%);

    --highlight: hsl(55 100% 80%);

    --input: hsl(214.3 31.8% 99.4%);

    --ring: hsl(215 20.2% 65.1%);

    --scrollbar: hsl(0 0% 95%);
    --scrollbar-foreground: hsl(0 0% 76%);

    --radius: 0.5rem;


    --p-page: 1rem;
    --p-page-lg: 2rem;
  }

  .dark {
    --background: var(--dark);
    --backgroundValue: var(--darkValue);
    --backgroundInter: var(--darkInter);
    --background2: var(--dark2);
    --foreground: var(--white);

    --secondary: hsl(243, 75%, 55%);
    --secondary-foreground: hsl(198, 49%, 80%);

    --accent: hsl(243, 75%, 70%);
    --accent-foreground: var(--primary-foreground);

    --muted: hsl(236, 7%, 15%);
    --muted-foreground: hsl(0 0% 80%);

    --popover: hsl(236, 7%, 12%);
    --popover-foreground: var(--primary-foreground);

    --card: hsl(236, 7%, 10%);
    --card-foreground: var(--primary-foreground);

    --border: var(--muted-foreground);

    --highlight: hsl(55 100% 20%);

    --input: var(--muted);

    --ring: var(--primary);

    --scrollbar: hsl(236, 7%, 20%);
    --scrollbar-foreground: hsl(0 0% 45%);
  }
}

@layer base {
  * {
    @apply border-border;
  }

  *, *::before, *::after {
    box-sizing: border-box;
    border: 0 solid var(--border);
  }

  body {
    @apply text-base bg-background text-foreground;
    color: var(--foreground);
    overflow-y: scroll;
  }

  body.noScrollBar {
    overflow-y: hidden !important;
  }

  form {
    @apply contents;
  }

  input {
    @apply bg-input;
  }

  textarea {
    @apply bg-input;
  }

  button {
    @apply outline-none;
  }

  ul {
    @apply mb-6
  }

  *:focus, *:focus-visible {
    @apply outline-none;
  }

  /* Pretty scrollbar --------------------------------------------------------- */

  ::-webkit-scrollbar-track {
    border: 6px solid var(--scrollbar);
    background-color: var(--scrollbar);
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-foreground);
    border-radius: 2px;
  }

  /* No scrollbar --------------------------------------------------------- */

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
  }

  /* No Highlight on mobile --------------------------------------------------------- */

  .noHighlight {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .noHighlight:focus {
    outline: none !important;
  }

  /* Input number without arrows ----------------------------------------------------- */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }


  /* Hack for correct screen height on mobile ---------------------------------------- */
  /* (see: https://stackoverflow.com/questions/61308575/tailwind-h-real-screen-doesn-t-work-properly-on-mobile-devices) */

  .h-real-screen {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .min-h-real-screen {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .max-h-real-screen {
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
  }

  /* Pretty input file --------------------------------------------------------------- */

  input[type=file] {
    padding: 0 !important;
    font-size: 0 !important;
  }

  input[type=file]::file-selector-button {
    margin: 2px 0 2px 2px;
    font-size: 16px !important;
    border: none;
    background: var(--primary);
    padding: 8px 16px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }

  input[type=file]::file-selector-button:hover {
    opacity: 80%;
  }

  /* Card flipery --------------------------------------------------------- */

  .flip {
    width: 200px;
    height: 200px;
    text-align: center;
    perspective: 600px;
  }

  .flip-content {
    width: 100%;
    height: 100%;
    transition: transform 0.4s;
    transform-style: preserve-3d;
  }

  .flip:hover .flip-content {
    transform: rotateY(180deg);
    transition: transform 0.3s;
  }

  .flip-front, .flip-back {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
  }

  .flip-back {
    transform: rotateY(180deg);
  }

}
