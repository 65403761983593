/* Global styles for dynamic spacing */

:root {
  /* Default spacings for mobile devices (screens smaller than 640px) */
  --spacing-page: 1rem;
  --spacing-card: 0.8rem;
  --spacing-section: 2rem;
  --spacing-element: 0.4rem;
  --spacing-text: 0.4rem;
  --spacing-inline: 0.3rem;
  --spacing-vertical: 0.8rem;
  --spacing-form: 0.7rem;
  --spacing-modal: 1.5rem;
  --spacing-nav: 1rem;
}

/* Small screens (sm: ≥640px) */
@media screen and (min-width: 640px) {
  :root {
    --spacing-page: 1.5rem;
    --spacing-card: 1rem;
    --spacing-section: 3rem;
    --spacing-element: 0.6rem;
    --spacing-text: 0.5rem;
    --spacing-inline: 0.4rem;
    --spacing-form: 1rem;
  }
}

/* Medium screens (md: ≥768px) */
@media screen and (min-width: 768px) {
  :root {
    --spacing-page: 1.8rem;
    --spacing-card: 1.1rem;
    --spacing-element: 0.8rem;
    --spacing-text: 0.6rem;
    --spacing-section: 4rem;
    --spacing-form: 1.2rem;
    --spacing-modal: 2rem;
  }
}

/* Large screens (lg: ≥1024px) */
@media screen and (min-width: 1024px) {
  :root {
    --spacing-page: 2rem;
    --spacing-card: 1.2rem;
    --spacing-section: 5rem;
    --spacing-element: 1rem;
    --spacing-inline: 0.4rem;
    --spacing-form: 1.5rem;
    --spacing-modal: 2.5rem;
  }
}

/* Extra large screens (xl: ≥1280px) */
@media screen and (min-width: 1280px) {
  :root {
    --spacing-page: 2.2rem;
    --spacing-card: 1.4rem;
    --spacing-section: 6rem;
    --spacing-form: 1.8rem;
    --spacing-modal: 3rem;
  }
}
